<template>
	<div>
		<div class="bg-gray-100 -mt-3 pt-8 pb-12">
			<div v-if="metamaskAddress && allowed" class="min-h-screen container mx-auto mt-24 text-purple-600">
				<h1 class="text-xl">
					Number of products for sale:
					{{ products && products.length ? products.length : 0 }}
				</h1>
				<h1 class="text-xl">Total existing $PZA in the ecosystem: {{ totalPizzas }} $PZA</h1>
				<h1 class="text-xl">Total spendable $PZA in the ecosystem: {{ totalToBuy }} $PZA</h1>
				<div class="flex">
					<button
						class="bg-blue-600 text-white px-4 py-2 uppercase mt-2"
						@click="
							create = false;
							edit = false;
							view = true;
						"
					>
						View products
					</button>
					<button
						class="ml-4 bg-green-600 text-white px-4 py-2 uppercase mt-2"
						@click="
							create = true;
							view = false;
						"
					>
						Create product
					</button>
				</div>
				<div v-if="create || edit" class="flex flex-col max-w-xl mt-8 bg-white p-8">
					<h1 class="text-purple-600 uppercase text-xl my-4">create product</h1>
					<p class="text-purple-600">Project name</p>
					<input v-model="product.name" class="my-2 p-2 rounded text-black border border" type="text" placeholder="project name" />
					<p class="text-purple-600">1 liner about the item</p>
					<input v-model="product.description" class="mt-2 mb-3 p-2 rounded text-black border" type="text" placeholder="description" />
					<p class="text-purple-600">
						Image Link - be mindfull of the image size
						<br />
						<a href="https://imgbb.com/" target="_blank" class="bg-green-600 text-white text-xs px-4 py-1"
							>Upload here and get link (no need for an account)</a
						>
					</p>
					<input v-model="product.img" class="my-2 p-2 rounded text-black border" type="text" placeholder="image link" />
					<!-- <p class="text-white">Category</p>
        <input v-model="product.category" class="my-2 p-2 rounded text-black border" type="text" placeholder="category"> -->
					<p class="text-purple-600">Website - optional</p>
					<input v-model="product.links.website" class="my-2 p-2 rounded text-black border" type="text" placeholder="website" />
					<p class="text-purple-600">Twitter - optional</p>
					<input v-model="product.links.twitter" class="my-2 p-2 rounded text-black border" type="text" placeholder="twitter" />
					<p class="text-purple-600">Discord - optional</p>
					<input v-model="product.links.discord" class="my-2 p-2 rounded text-black border" type="text" placeholder="discord" />
					<p class="text-purple-600">Instagram - optional</p>
					<input v-model="product.links.instagram" class="my-2 p-2 rounded text-black border" type="text" placeholder="instagram" />
					<p class="text-purple-600">Price</p>
					<input v-model="product.price" class="my-2 p-2 rounded text-black border" type="number" placeholder="price" min="1" />
					<p v-if="edit" class="text-purple-600">Total supply</p>
					<input
						v-if="edit"
						v-model="totalSupply"
						class="my-2 p-2 rounded text-black border"
						type="number"
						placeholder="total supply"
						min="1"
					/>
					<p class="text-purple-600">Quantity</p>
					<input v-model="product.quantity" class="my-2 p-2 rounded text-black border" type="number" placeholder="quantity" min="1" />
					<p class="text-purple-600">Does this fund community wallet?</p>
					<div class="flex items-center">
						<input class="p-4 h-12 mr-4" type="checkbox" :checked="product.category == 'merch' ? true : false" @click="toggleCategory" />
					</div>

					<p class="text-purple-600">This item can only be acquired once</p>
					<div class="flex items-center">
						<input
							class="p-4 h-12 mr-4"
							type="checkbox"
							:checked="product.unique_buy ? true : false"
							@click="product.unique_buy = !product.unique_buy"
						/>

						{{ product.unique_buy ? 'Yes, only once' : 'No, multiple times' }}
					</div>
					<hr v-if="edit" class="my-4" />
					<div v-if="edit" class="flex flex-col">
						<p class="text-purple-600">Mark as sold will hide the item from the shop for the users</p>
						<input class="p-4 h-12 mr-4" type="checkbox" :checked="product.sold ? true : false" @click="product.sold = !product.sold" />

						{{ product.sold ? 'Yes, Mark as sold' : 'Unmark from sold, let users buy' }}
					</div>
					<!-- <p class="text-white">Begin at - (optionnal)</p>
        <input v-model="product.begin_at" class="my-2 p-2 rounded text-black border" type="datetime-local" placeholder="begin">
        <p class="text-white">End at - (optionnal)</p>
        <input v-model="product.end_at" class="my-2 p-2 rounded text-black border" type="datetime-local" placeholder="end"> -->
					<button class="mt-4 bg-green-600 text-white uppercase font-semibold py-2 px-4" @click="productAction">
						{{ create ? 'Create' : 'Edit' }}
					</button>
					<!-- {{product}} -->
				</div>
				<div v-else-if="view">
					<h1 v-if="!products || (products && products.length == 0)" class="mt-12 uppercase text-2xl text-purple-600">
						No products in the database yet
					</h1>
					<h1 v-else class="mt-12 uppercase text-2xl text-purple-600">
						Mark as sold can be used to hide the product from the shop for the users
					</h1>
					<div class="mt-12 mx-auto w-full">
						<div class="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
							<div v-for="(p, i) in products" :key="i" class="bg-gray-900 bg-opacity-90 rounded-lg">
								<img :src="p.img" class="rounded-t-lg h-auto min-h-72 max-h-72 w-full object-cover" style="min-height: 288px" />
								<div class="p-4">
									<div class="flex flex-row items-center">
										<div class="flex-1 text-left">
											<div class="flex flex-col">
												<p class="text-white no-underline text-md font-bold">
													{{ p.name }}
												</p>
												<p class="text-xs text-gray-400 font-bold">{{ p.quantity }} left out of {{ p.total_supply }}</p>
											</div>
										</div>
										<div class="text-right">
											<p class="flex flex-col -mt-3">
												<span class="text-gray-400">PRICE</span>
												<span class="font-bold text-white">{{ p.price }} $PZA</span>
											</p>
										</div>
									</div>
								</div>
								<div class="text-sm text-white text-center mb-4">
									{{ p.description }}
								</div>
								<hr />
								<div class="my-4 text-white px-4">Unique buy: {{ p.unique_buy ? 'Yes' : 'No' }}</div>
								<div class="my-4 text-white px-4">Mark as sold: {{ p.sold ? 'Yes' : 'No' }}</div>
								<div class="flex justify-between w-full">
									<button class="bg-green-600 px-3 p-2 uppercase w-full text-white" @click="initialiseEdit(p)">EDIT</button>
									<button class="bg-yellow-600 px-3 p-2 uppercase w-full text-white" @click="hideProduct(p)">Mark as sold</button>
								</div>
								<div class="flex w-full">
									<button class="bg-blue-600 w-full py-2 px-4 uppercase text-white" @click="getUsers(p)">Download Users That Bought</button>
								</div>
								<div class="flex w-full">
									<button class="bg-red-600 w-full py-2 px-4 uppercase text-white" @click="deleteProduct(p.id)">Delete product</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="min-h-screen text-center">
				<h1 class="mt-24 uppercase text-2xl text-purple-600 font-semibold">Connect to metamask</h1>
			</div>
		</div>
	</div>
</template>

<script>
import Web3 from 'web3';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
	name: 'Admin',
	components: {},
	mounted() {
		this.getAllBalances();
		this.getProducts();
	},
	data() {
		return {
			view: false,
			create: true,
			edit: false,
			give: false,
			giveTo: '',
			totalSupply: 0,
			ammountToGive: 1,
			products: [],
			product: {
				name: '',
				img: '',
				description: '',
				sale_type: 'buy',
				begin_at: null,
				end_at: null,
				price: 1,
				quantity: 1,
				unique_buy: true,
				links: {
					website: '',
					twitter: '',
					discord: '',
					instagram: '',
				},
			},
			allowed: false,
			allowedAdmins: [
				'0xd4850927a6e3f30E2e3C3b14D98131Cf8e2D9634',
				'0xd83682D4818D6D044Ce613a19f605c2af7ab6729',
				'0xab7DDe540d93219906CC431cCA83723611312823',
				'0xD97851CEC4c57Cb7dBB114266F579500801adcea',
				'0xBc8f763673483e0c0F0928ffd631368317157354',
				'0xbe1f98c407e7030904fdac86219f5c9fb7029ecf',
				'0xDB1Ac1d3CaCF1bB80e3597bb0EE3CAF52D266dFa',
				'0xa86c148795b0758E6E245f30217bbD5Cdf8D324E',
			],
			sig: null,
			msg: null,
			totalPizzas: 0,
			totalToBuy: 0,
			deleted: false,
		};
	},
	methods: {
		toggleCategory() {
			if (this.product.category == 'merch') this.product.category = null;
			else this.product.category = 'merch';
		},

		async getUsers(product) {
			const productId = product.id;
			try {
				const { ethereum } = window;
				if (!ethereum) {
					return;
				}
				window.web3 = new Web3(ethereum);
				const web3 = window.web3;
				this.msg = 'Getting all users that bought product ' + product.name;
				this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
				const res = await axios.post('https://averagepunks.app/productWallets', {
					productId: productId,
					wallet: this.metamaskAddress,
					message: this.msg,
					signature: this.sig,
				});

				let csvContent = 'Discord,Wallet\n';
				res.data.wallets.forEach((r) => {
					csvContent += `${r[1]},${r[0]}\n`;
				});
				const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
				var link = document.createElement('a');
				link.setAttribute('href', encodedUri);
				link.setAttribute('download', product.name + '_export.csv');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (error) {
				this.showErrorMessage(error.message);
			}
		},
		async getAllBalances() {
			try {
				const res = await axios.get('https://averagepunks.app/allPizzas');
				this.totalPizzas = res.data.balances[0].sum;
			} catch (error) {
				let msg = '';
				if (error.response && error.response.data) msg = error.response.data.message;
				else msg = error.message;
				this.showErrorMessage(msg);
			}
		},
		productAction() {
			if (this.edit) this.editProduct();
			else if (this.create) this.createProduct();
		},
		async gift() {
			if (!this.giveTo || this.ammountToGive <= 0) return this.showErrorMessage('missing input');
			try {
				const { ethereum } = window;
				if (!ethereum) {
					return;
				}
				window.web3 = new Web3(ethereum);
				const web3 = window.web3;
				this.msg = 'You are giving ' + this.ammountToGive + ' $PZA to ' + this.giveTo;
				this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
				await axios.post('https://averagepunks.app/giftPizzas', {
					receiver: this.giveTo,
					amount: this.ammountToGive,
					wallet: this.metamaskAddress,
					message: this.msg,
					signature: this.sig,
				});
				this.create = false;
				this.edit = false;
				this.give = false;
				this.view = true;
				this.giveTo = '';
				this.ammountToGive = 1;
				this.showSuccess('$PZA gifted');
				this.getAllBalances();
			} catch (error) {
				this.showErrorMessage(error.message);
			}
		},
		async createProduct() {
			if (!this.product.name || !this.product.description || !this.product.img) return this.showErrorMessage('missing input');
			try {
				const { ethereum } = window;
				if (!ethereum) {
					return;
				}
				window.web3 = new Web3(ethereum);
				const web3 = window.web3;
				this.msg = 'Creating product for shop with address ' + this.metamaskAddress;
				this.product.total_supply = this.product.quantity;

				this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
				await axios.post('https://averagepunks.app/products', {
					product: this.product,
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.create = false;
				this.edit = false;
				this.view = true;
				this.showSuccess('product created');
				const res = await axios.post('https://averagepunks.app/allProducts', {
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.products = res.data.products;
			} catch (error) {
				this.showErrorMessage(error.message);
			}
		},
		initialiseEdit(p) {
			this.product =
				p.links && p.links.website
					? p
					: {
							...p,
							links: {
								website: '',
								twitter: '',
								discord: '',
								instagram: '',
							},
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  };
			this.view = false;
			this.edit = true;
			this.totalSupply = p.total_supply;
		},
		async editProduct() {
			if (!this.product.name || !this.product.description || !this.product.img) return this.showErrorMessage('missing input');
			try {
				const { ethereum } = window;
				if (!ethereum) {
					return;
				}
				window.web3 = new Web3(ethereum);
				const web3 = window.web3;
				this.msg = 'Editing product for shop with address ' + this.metamaskAddress;
				this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
				if (this.totalSupply != this.product.total_supply) {
					// this.product.quantity += this.totalSupply - this.product.total_supply;
					this.product.total_supply = this.totalSupply;
				}
				await axios.post('https://averagepunks.app/edit-product', {
					product: this.product,
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.create = false;
				this.edit = false;
				this.view = true;
				this.showSuccess('product edited');
				const res = await axios.post('https://averagepunks.app/allProducts', {
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.products = res.data.products;
			} catch (error) {
				this.showErrorMessage(error.message);
			}
		},
		async hideProduct(product) {
			const confirm = window.confirm('Are you sure you want to hide this item from sale ?');
			if (!confirm) return;
			try {
				const { ethereum } = window;
				if (!ethereum) {
					return;
				}
				window.web3 = new Web3(ethereum);
				const web3 = window.web3;
				this.msg = 'Mark product as sold with address ' + this.metamaskAddress;
				this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
				product.sold = true;
				await axios.post('https://averagepunks.app/edit-product', {
					product: product,
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.showSuccess('product deleted');
				const res = await axios.post('https://averagepunks.app/allProducts', {
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.products = res.data.products;
			} catch (error) {
				this.showErrorMessage(error.message);
			}
		},
		async deleteProduct(product) {
			const confirm = window.confirm('Are you sure you want to delete this item from sale, this will delete all the sales associated?');
			if (!confirm) return;
			try {
				const { ethereum } = window;
				if (!ethereum) {
					return;
				}
				window.web3 = new Web3(ethereum);
				const web3 = window.web3;
				this.msg = 'Delete product with address ' + this.metamaskAddress + ' this will delete all the sales associated';
				this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
				await axios.post('https://averagepunks.app/delete-product', {
					productId: product,
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.create = false;
				this.edit = false;
				this.view = true;
				this.showSuccess('product deleted');
				const res = await axios.post('https://averagepunks.app/allProducts', {
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.products = res.data.products;
			} catch (error) {
				this.showErrorMessage(error.message);
			}
		},
		async getProducts() {
			try {
				if (!this.view) return;
				const { ethereum } = window;
				if (!ethereum) {
					return;
				}
				window.web3 = new Web3(ethereum);
				const web3 = window.web3;
				this.msg = 'Get all products for shop with address ' + this.metamaskAddress;
				this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
				const res = await axios.post('https://averagepunks.app/allProducts', {
					signature: this.sig,
					wallet: this.metamaskAddress,
					message: this.msg,
				});
				this.products = res.data.products;
			} catch (error) {
				this.showErrorMessage(error.message);
			}
		},
		showErrorMessage(message) {
			this.$toast.error(message, {
				position: 'bottom-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.79,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false,
			});
		},
		showSuccess() {
			this.$toast.success(`Success`, {
				position: 'bottom-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.79,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false,
			});
		},
	},
	computed: {
		...mapGetters({
			metamaskAddress: 'connection/metamaskAddress',
		}),
	},
	watch: {
		metamaskAddress: {
			immediate: true,
			handler: function (val) {
				if (val) {
					const found = this.allowedAdmins.find((a) => a.toLowerCase() === val.toLowerCase());
					this.allowed = found === undefined ? false : true;
					if (found === undefined) this.$router.push('/');
				}
			},
		},
		view(val) {
			if (val) {
				this.getProducts();
			}
		},
		create(val) {
			if (val) {
				this.product = {
					name: '',
					img: '',
					description: '',
					sale_type: 'buy',
					begin_at: null,
					end_at: null,
					price: 1,
					quantity: 1,
					unique_buy: true,
					links: { website: '', twitter: '', discord: '', instagram: '' },
				};
			}
		},
		products(val) {
			if (val) {
				this.totalToBuy = val.filter((p) => p.sold === false).reduce((a, e) => a + e.price * e.quantity, 0);
			}
		},
	},
};
</script>
